import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import { Component, Prop, Ref } from 'vue-property-decorator'
import { mapState } from 'vuex'
import Container from 'typedi'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'

interface ParticipantData {
  uuid: string;
  email: string;
  [index: string]: any;
}

@Component({
  name: 'GtrRegistrationForm',
  computed: {
    ...mapState('event', ['eventAllContent'])
  }
})
export default class GtrRegistrationForm extends GtrSuper {
  @Prop({ required: true, type: Boolean, default: false })
  visible: boolean | undefined

  @Ref('regFormIframe')
  readonly regFormIframe!: HTMLIFrameElement

  eventAllContent!: any

  data () {
    return {
      sendInvoiceEmail: false,
      newlyAddedParticipant: null as ParticipantData | null
    }
  }

  mounted () {
    window.addEventListener(
      'message',
      async (event) => {
        if (event.origin === process.env.VUE_APP_REG_URL && event.source === this.regFormIframe.contentWindow) {
          this.$data.newlyAddedParticipant = event.data
          if (this.$data.sendInvoiceEmail) {
            await this.sendEmailToOneRegistration()
          }
        }
      },
      false
    )
  }

  get regUrl (): string {
    if (this.eventAllContent) {
      return `${process.env.VUE_APP_REG_URL}/${this.eventAllContent.event.event_identifier}`
    }
    return ''
  }

  handleClose () {
    this.$emit('close')
  }

  async sendEmailToOneRegistration () {
    try {
      this.$data.loading = true
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        company_uuid: this.$route.params.company_uuid,
        registration_uuid: this.$data.newlyAddedParticipant.uuid,
        email_name: 'invoice',
        data: {
          event_uuid: this.$route.params.event_uuid,
          company_uuid: this.$route.params.company_uuid,
          registration_uuid: this.$data.newlyAddedParticipant.uuid,
          email_name: 'invoice',
          to: [this.$data.newlyAddedParticipant.email],
          cc: [],
          bcc: ['receipts@gtrmeetings.com']
        }
      }
      await this.$store.dispatch('email/sendEmailToOneRegistration', payload)
      Container.get(Notification).success('Email successfully sent.')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  addAnotherParticipant () {
    this.regFormIframe.src = this.regUrl
    this.$data.newlyAddedParticipant = null
  }
}
